import { createContext, useContext, useState } from 'react'
import { ProviderProps } from 'types/common/ProviderProps'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_UPDATE, MAIL_TEMPLATES } from 'utils/constants'
import { MailTemplateRow } from 'types/rows/MailTemplateRow'
import {
  COMMON_ERROR_MESSAGE,
  CONTAIN_ERROR_TITLE,
  FATAL_ERROR_MESSAGE,
  FATAL_ERROR_TITLE,
  getConfirmMessage,
  getConfirmTitle,
  getNotifyMessage,
  getNotifyTitle,
  lengthLessThanMessage,
  requiredMessage
} from 'utils/messageUtil'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { updateMailTemplate } from 'api/mailTemplates'
import { MailTemplateInfo } from 'types/infos/MailTemplateInfo'
import { hasNotValue, invalidMaxLength } from 'utils/validators'

/**
 *
 */
interface MailTemplateState {
  templateType: number
  templateTypeLabel: string
  title: string
  body: string
  msg4Title: string
  msg4Body: string

  changeTitle: (val: string) => void
  changeBody: (val: string) => void

  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  showConfirmDialog: boolean
  showNotifyDialog: boolean

  closeErrorDialog: () => void
  closeConfirmDialog: () => void
  closeNotifyDialog: () => void

  refresh: (type: EXEC_TYPE, info?: any) => void // TODO なおせ
  restore: () => void
  update: () => void
  clear: () => void
  doCallback: () => Promise<void>
}

/**
 *
 */
export const MailTemplateStateContext = createContext({} as MailTemplateState)

/**
 *
 * @returns
 */
export const useMailTemplateState = () => useContext(MailTemplateStateContext)

/**
 *
 * @param props
 * @returns
 */
export const MailTemplateStateProvider = (props: ProviderProps) => {
  const { children } = props
  const gStates = useGlobalState()

  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_UPDATE)

  const [originalInfo, setOriginalInfo] = useState<MailTemplateRow | null>(null)
  const [mailTemplateId, setMailTemplateId] = useState<number | null>(null)
  const [templateType, setTemplateType] = useState<number>(-1)
  const [title, setTitle] = useState<string>('')
  const [body, setBody] = useState<string>('')

  const [msg4Title, setMsg4Title] = useState<string>('')
  const [msg4Body, setMsg4Body] = useState<string>('')

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false)

  // 導出State
  const templateTypeLabel = MAIL_TEMPLATES[templateType]

  // changeイベントリスナ
  const changeTitle = (title: string): void => {
    setTitle(title)
    setMsg4Title('')
  }

  const changeBody = (body: string): void => {
    setBody(body)
    setMsg4Body('')
  }

  // ダイアログ系イベント

  /**
   * エラーメッセージを表示する
   */
  const showErrorMessage = (): void => {
    setDialogTitle(CONTAIN_ERROR_TITLE)
    setDialogContents(COMMON_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 確認メッセージを表示する
   * @param type
   */
  const showConfirmMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getConfirmTitle(type))
    setDialogContents(getConfirmMessage(type, 'メールテンプレート情報'))
    setShowConfirmDialog(true)
  }

  /**
   * 通知メッセージを表示する
   * @param type
   */
  const showNotifyMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getNotifyTitle(type))
    setDialogContents(getNotifyMessage(type, 'メールテンプレート情報'))
    setShowNotifyDialog(true)
  }

  const closeErrorDialog = (): void => {
    setShowErrorDialog(false)
  }
  const closeConfirmDialog = (): void => {
    setShowConfirmDialog(false)
  }
  const closeNotifyDialog = (): void => {
    setShowNotifyDialog(false)
  }

  /**
   *
   * @param type
   * @param info
   * @returns
   */
  const refresh = (type: EXEC_TYPE, info?: MailTemplateRow) => {
    setOriginalInfo(info ?? null)
    setMailTemplateId(info!.mailTemplateId)
    setTemplateType(info!.templateType)
    setTitle(info!.title)
    setBody(info!.body)
  }

  /**
   *
   * @returns
   */
  const update = () => {
    if (checkAll() === false) {
      showErrorMessage()
      return
    }
    // // 実行確認
    setExecType(EXEC_UPDATE)
    showConfirmMessage(EXEC_UPDATE)
  }
  const doUpdate = async () => {
    closeConfirmDialog()
    const params: MailTemplateInfo = createParameters()
    try {
      const result = await updateMailTemplate(params)
      showNotifyMessage(EXEC_UPDATE)
    } catch (e) {
      // SessionTimeout処理
      if (gStates.handleSessionExpired(e)) return
      // 致命的エラー処理
      showFatalErrorMessage()
    }
  }

  const clear = () => {
    clearValues()
    clearMsgs()
  }

  /**
   *
   */
  const restore = () => {
    restoreValues()
    clearMsgs()
  }

  /**
   *
   */
  const doCallback = async () => {
    if (!execType) throw new Error('実行種別が指定されていません。')
    if (execType === EXEC_UPDATE) await doUpdate()
  }

  /**
   *
   */
  const restoreValues = () => {
    if (!originalInfo) {
      setTitle('')
      setBody('')
      return
    }
    setTitle(originalInfo!.title)
    setBody(originalInfo!.body)
  }

  const clearValues = () => {
    setMailTemplateId(null)
    setTitle('')
    setBody('')
  }
  /**
   *
   */
  const clearMsgs = () => {
    setMsg4Title('')
    setMsg4Body('')
  }

  /**
   *
   * @returns
   */
  const checkAll = (): boolean => {
    const result: boolean[] = []
    result.push(checkTitle())
    result.push(checkBody())
    return result.includes(false) === false
  }

  /**
   *
   * @returns
   */
  const checkTitle = (): boolean => {
    // 必須入力
    if (hasNotValue(title)) {
      setMsg4Title(requiredMessage('タイトル'))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(title, 200)) {
      setMsg4Title(lengthLessThanMessage('タイトル', 200))
      return false
    }

    return true
  }

  /**
   *
   * @returns
   */
  const checkBody = (): boolean => {
    // 必須入力
    if (hasNotValue(body)) {
      setMsg4Body(requiredMessage('本文'))
      return false
    }
    // 桁数チェック
    if (invalidMaxLength(body, 2000)) {
      setMsg4Body(lengthLessThanMessage('本文', 2000, body.length))
      return false
    }
    return true
  }

  const createParameters = (): MailTemplateInfo => {
    return {
      mailTemplateId: mailTemplateId!,
      templateType,
      title,
      body
    }
  }

  const globalStates = {
    templateType,
    templateTypeLabel,
    title,
    body,
    msg4Title,
    msg4Body,

    changeTitle,
    changeBody,

    dialogTitle,
    dialogContents,
    showErrorDialog,
    showConfirmDialog,
    showNotifyDialog,

    closeErrorDialog,
    closeConfirmDialog,
    closeNotifyDialog,

    refresh,
    restore,
    update,
    clear,
    doCallback
  }

  return (
    <MailTemplateStateContext.Provider value={globalStates}>
      {children}
    </MailTemplateStateContext.Provider>
  )
}

export default MailTemplateStateProvider
