import axios from 'api/common/axios'
import {
  RESERVATION_SUSPENDED_BASE,
  RESERVATION_SUSPENDED_FOR_DATE,
  RESERVATION_SUSPENDED_FOR_FACILITIES
} from './common/url'
import { API_RESPONSE } from 'types/common/Api'
import { SuspendedInfo } from 'types/ui/SuspendedInfo'
import { ReservationSuspendListElement } from 'types/ui/ReservationSuspendListElement'
import { ReservationSuspendInfo } from 'types/infos/ReservationSuspendInfo'
import { RESPONSE_NG } from 'utils/constants'

/**
 *
 */
export const findAll = async () => {
  try {
    type retType = API_RESPONSE<ReservationSuspendListElement[]>
    const response = await axios.get<retType>(RESERVATION_SUSPENDED_BASE)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const result = response.data.data.map((d) => {
      d.suspendStartDatetime = new Date(d.suspendStartDatetime)
      d.suspendEndDatetime = new Date(d.suspendEndDatetime)
      return d
    })

    return result
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param targetDate
 * @returns
 */
export const findForDay = async (targetDate: Date) => {
  try {
    type retType = API_RESPONSE<SuspendedInfo[]> // TODO 後で正しい型に変換すること
    const numValue = targetDate.getTime()
    const response = await axios.get<retType>(`${RESERVATION_SUSPENDED_FOR_DATE}/${numValue}`)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const result = response.data.data

    return result
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param targetDate
 * @returns
 */
export const findForFacilityAll = async (facilityId: number): Promise<ReservationSuspendInfo[]> => {
  try {
    type retType = API_RESPONSE<ReservationSuspendInfo[]>
    const url = `${RESERVATION_SUSPENDED_FOR_FACILITIES}/${facilityId}/all`

    const response = await axios.get<retType>(url)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const result = response.data.data.map((s) => {
      s.suspendStartDatetime = new Date(s.suspendStartDatetime)
      s.suspendEndDatetime = new Date(s.suspendEndDatetime)
      return s
    })

    return result
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    //
  }
}
/**
 *
 * @param targetDate
 * @returns
 */
export const findForFacility = async (facilityId: number, from: Date, to: Date) => {
  try {
    type retType = API_RESPONSE<SuspendedInfo[]>
    const params = {
      from: from.getTime(),
      to: to.getTime()
    }

    const response = await axios.get<retType>(
      `${RESERVATION_SUSPENDED_FOR_FACILITIES}/${facilityId}`,
      { params }
    )

    if (response.data.status === RESPONSE_NG) {
      // throw new Error(response.data.code.toString())
    }

    const result = response.data.data.map((s) => {
      s.suspendStartDatetime = new Date(s.suspendStartDatetime)
      s.suspendEndDatetime = new Date(s.suspendEndDatetime)
      return s
    })

    return result
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param params
 */
export const insert = async (params: ReservationSuspendInfo): Promise<void> => {
  try {
    type retType = API_RESPONSE<void>
    const response = await axios.post<retType>(`${RESERVATION_SUSPENDED_BASE}`, params)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param params
 */
export const update = async (params: ReservationSuspendInfo): Promise<void> => {
  try {
    type retType = API_RESPONSE<void>
    const id = params.reservationSuspendId
    const response = await axios.put<retType>(`${RESERVATION_SUSPENDED_BASE}/${id}`, params)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param ReservationSuspendId
 */
export const remove = async (ReservationSuspendId: number) => {
  //
  try {
    const response = await axios.delete(`${RESERVATION_SUSPENDED_BASE}/${ReservationSuspendId}`)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
