import InputText from "components/common/atoms/InputText";
import fStyles from "styles/utils/form.module.scss";
import { useChangePasswordState } from "./ChangePasswordStateProvider";

const ChangePasswordForm = () => {
  const states = useChangePasswordState();

  return (
    <>
      <section className={fStyles.detailForm}>
        <div className={fStyles.row}>
          <span className={fStyles.label}>（変更前）パスワード</span>
          <span className={fStyles.input}>
            <InputText
              id="currPass"
              type="password"
              size={50}
              value={states.currPass}
              onChange={(event) => states.setCurrPass(event.target.value)}
              errorMsg={states.msg4CurrPass}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>新パスワード</span>
          <span className={fStyles.input}>
            <InputText
              id="newPass"
              type="password"
              size={50}
              value={states.newPass}
              onChange={(event) => states.setNewPass(event.target.value)}
              errorMsg={states.msg4NewPass}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>新パスワード（確認）</span>
          <span className={fStyles.input}>
            <InputText
              id="newPass2"
              type="password"
              size={50}
              value={states.newPass2}
              onChange={(event) => states.setNewPass2(event.target.value)}
              errorMsg={states.msg4NewPass2}
            />
          </span>
        </div>
        <div className={fStyles.footerButtons}>
          <button className={fStyles.button} onClick={states.update}>
            変更
          </button>
          <button className={`${fStyles.button} ${fStyles.clear}`} onClick={states.clear}>
            クリア
          </button>
          <button className={fStyles.button} onClick={states.goBack}>
            戻る
          </button>
        </div>
      </section>
    </>
  );
};

export default ChangePasswordForm;
