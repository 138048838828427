import axios, { AXIOS_CONFIG } from 'api/common/axios'
import { CONTRACTORS_BASE, LOGIN_URL, LOGOUT_URL, RESERVER_ALL_URL } from 'api/common/url'
import { cloneDeep } from 'lodash'
import { API_RESPONSE } from 'types/common/Api'
import { ContractorInfo, ReserverInfo } from 'types/infos/ContractorInfo'
import { LoginContractorInfo } from 'types/infos/LoginInfo'
import { RESPONSE_NG, RESPONSE_OK, USER_TYPE_CONTRACTOR } from 'utils/constants'
import { toDigestValue } from 'utils/encriptUtils'

interface LoginParams {
  loginId: string
  password: string
}

/**
 *
 */
export const login = async (params: LoginParams): Promise<API_RESPONSE<LoginContractorInfo>> => {
  //
  try {
    const hashedParams = cloneDeep(params)
    const hashed = await toDigestValue(hashedParams.password)
    hashedParams.password = hashed
    const response = await axios.post<API_RESPONSE<LoginContractorInfo>>(
      LOGIN_URL,
      hashedParams,
      AXIOS_CONFIG
    )

    // ログイン処理：SessionTimeoutチェックは不要

    // エラーチェックは読み出し元で実施する
    return response.data
    //
  } catch (e) {
    console.error(e)
    throw e
  }
}

/**
 *
 */
export const logout = async () => {
  try {
    // ログアウトリクエストの送信
    type resType = API_RESPONSE<null>
    const response = await axios.post<resType>(LOGOUT_URL)

    // ログアウト処理：SessionTimeoutチェックは不要

    return response.data.status === RESPONSE_OK
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const findAll = async (): Promise<ContractorInfo[]> => {
  try {
    type retType = API_RESPONSE<ContractorInfo[]>
    const response = await axios.get<retType>(CONTRACTORS_BASE)

    // SessionTimeoutチェック
    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param contractorId
 */
export const findOne = async (contractorId: number) => {
  try {
    type resType = API_RESPONSE<ContractorInfo>
    const response = await axios.get<resType>(`${CONTRACTORS_BASE}/${contractorId}`)

    // SessionTimeoutチェック
    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 * 施設予約の予約者となりうる契約社全てを取得する。
 * @returns
 */
export const findReserverAll = async () => {
  try {
    type resType = API_RESPONSE<ReserverInfo[]>
    const response = await axios.get<resType>(`${RESERVER_ALL_URL}`)

    // SessionTimeoutチェック
    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param params
 */
export const registerContractor = async (params: ContractorInfo) => {
  //
  try {
    params.userType = USER_TYPE_CONTRACTOR // 契約社登録処理 -> ユーザー種別「契約社」を設定
    const hashed = await toDigestValue(params.password)
    params.encryptedPassword = hashed
    const response = await axios.post<API_RESPONSE<void>>(CONTRACTORS_BASE, params, AXIOS_CONFIG)

    // エラーチェックは読み出し元で実施する
    return response.data
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param params
 */
export const updateContractor = async (params: ContractorInfo) => {
  //
  try {
    const contractorId = params.contractorId
    const hashed = await toDigestValue(params.password)
    params.encryptedPassword = hashed
    const response = await axios.put<API_RESPONSE<void>>(
      `${CONTRACTORS_BASE}/${contractorId}`,
      params,
      AXIOS_CONFIG
    )

    // エラーチェックは読み出し元で実施する
    return response.data
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param contractorId
 */
export const removeContractor = async (contractorId: number) => {
  //
  try {
    const response = await axios.delete(`${CONTRACTORS_BASE}/${contractorId}`, AXIOS_CONFIG)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
