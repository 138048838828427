import { ChangeEvent } from 'react'
import { Tooltip } from 'react-tooltip'
import styles from 'components/common/atoms/TextArea.module.scss'

interface TextAreaProps {
  id: string
  cols: number
  rows: number
  fontSize?: number
  value: string // 表示値
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void // 入力イベントハンドラ
  errorMsg?: string
  disabled?: boolean
}

const TextArea = ({ id, cols, rows, fontSize, value, onChange, errorMsg, disabled }: TextAreaProps) => {
  // デフォルト値
  const colCount = cols ?? 20
  const rowCount = rows ?? 3
  const fSize = (fontSize ?? 20) + 'px'

  // style属性
  const bgColor = !!errorMsg ? 'pink' : ''
  // ツールチップ制御
  const isShowTooltip = !errorMsg
  //
  const isDisabled = !!disabled

  return (
    <>
      <textarea
        className={styles.textArea}
        cols={colCount}
        rows={rowCount}
        style={{ backgroundColor: bgColor, fontSize: fSize }}
        value={value}
        onChange={(event) => onChange!(event)}
        data-tooltip-id={`textarea-tooltip_${id}`}
        disabled={isDisabled}
      ></textarea>
      <Tooltip
        id={`textarea-tooltip_${id}`}
        delayShow={300}
        hidden={isShowTooltip}
        place="top-end"
        style={{
          backgroundColor: 'var(--common-control-color)',
          borderRadius: '10px',
          color: 'red'
        }}
      >
        <h3>{errorMsg}</h3>
      </Tooltip>
    </>
  )
}

export default TextArea
