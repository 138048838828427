import { Helmet } from 'react-helmet-async'
import ContractorsForm from 'components/contractors/contractors/ContractorsForm'
import { ContractorsStateProvider } from 'components/contractors/contractors/ContractorsStateProvider'
import { TITLE_CONTRACTOR_MANAGE } from 'utils/constants'

/**
 * 契約社一覧画面
 */
const Contractors = () => {
  return (
    <ContractorsStateProvider>
      <Helmet>
        <title>{TITLE_CONTRACTOR_MANAGE}</title>
      </Helmet>
      <ContractorsForm />
    </ContractorsStateProvider>
  )
}

export default Contractors
