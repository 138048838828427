import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import styles from 'components/reservations/reservations/ReservationsForm.module.scss'
import { DateListElement } from 'types/rows/DateListElement'
import { FacilityListElement } from 'types/rows/FacilityListElement'
import { INDEX_TO_TIME_MAP } from 'utils/constants'

interface Props {
  rowIndex: number
  colIndex: number
  facilityInfo?: FacilityListElement | null
  dateInfo?: DateListElement | null
  disabledIndex?: number
  isReadOnly?: boolean
  onClick: (rowIndex: number, colIndex: number) => void
}

/**
 *
 * @param props
 * @returns
 */
const ReservationCell = (props: Props) => {
  const { rowIndex, colIndex, facilityInfo, dateInfo, disabledIndex, isReadOnly, onClick } = props

  const clickHandler = () => {
    if (isDisabled || isReadOnly) return
    onClick(rowIndex, colIndex)
  }

  const toolTipTitle = facilityInfo ? facilityInfo?.facilityName : dateInfo?.wareki
  const toolTipContent = `<div>${toolTipTitle}<br />${INDEX_TO_TIME_MAP[colIndex]}</div>`
  // 使用可能時刻：現在の時刻（30分単位で換算）までを使用可能とする => 直前の30分単位までが使用不可
  const isDisabled = colIndex <= disabledIndex!
  const isDisabledStyle = isDisabled ? styles.disabled : ''
  const isReadOnlyStyle = isReadOnly ? styles.readOnly : ''

  return (
    <>
      <div
        id={`reservation-cell2_${rowIndex}_${colIndex}`}
        className={`${styles.timeCell} ${isDisabledStyle} ${isReadOnlyStyle}`}
        onClick={clickHandler}
        data-tooltip-id={`reservation-cell_${rowIndex}_${colIndex}`}
        data-tooltip-html={toolTipContent}
      >
        &nbsp;
        {(!isDisabled && !isReadOnly) && (
          <Tooltip
            id={`reservation-cell_${rowIndex}_${colIndex}`}
            place="top-start"
            style={{
              backgroundColor: 'lightgray',
              borderRadius: '10px',
              color: '#333333',
              zIndex: '4'
            }}
          ></Tooltip>
        )}
      </div>
    </>
  )
}

export default ReservationCell
