import { ListElement, TimeToUnit } from 'types/common/ListElement'

// ページタイトル
export const TITLE_BASE = '1st base 施設予約'
export const TITLE_LOGIN = `ログイン | ${TITLE_BASE}`
export const TITLE_ADMIN_TOP = `管理者トップ | ${TITLE_BASE}`
export const TITLE_CONTRACTOR_MANAGE = `契約社管理 | ${TITLE_BASE}`
export const TITLE_RESERVE_BY_DATE = `年月日から施設予約 | ${TITLE_BASE} | `
export const TITLE_RESERVE_BY_FACILITY = `施設から施設予約 | ${TITLE_BASE} | `
export const TITLE_FACILITY_MANAGE = `施設管理 | ${TITLE_BASE} | `
export const TITLE_FACILITY_NOTIFICATION = `お知らせ管理 | ${TITLE_BASE} | `
export const TITLE_MANAGE_FACILITY_SUSPEND = `施設利用停止 | ${TITLE_BASE} | `
export const TITLE_MANAGE_FILE = `ファイル管理 | ${TITLE_BASE} | `
export const TITLE_MANAGE_MAIL_TEMPLATE = `メール管理 | ${TITLE_BASE} | `

export const TITLE_CONTRACTOR_TOP = `トップ | ${TITLE_BASE}`
export const TITLE_CONTRACTOR_INFO = `契約社情報 | ${TITLE_BASE}`

// API
export const RESPONSE_OK = 'OK'
export const RESPONSE_NG = 'NG'

// コード「ユーザー種別」
export const USER_TYPE_ADMIN: number = 1
export const USER_TYPE_CONTRACTOR: number = 2

// ロケール
export const LOCALE = 'ja'

// 日付・時刻（時分）フォーマット
export const FORMAT_DATE = 'yyyy/MM/dd'
export const FORMAT_DATETIME = 'yyyy/MM/dd HH:mm'

export const EXEC_INSERT = 'INSERT'
export const EXEC_UPDATE = 'UPDATE'
export const EXEC_DELETE = 'DELETE'
export const EXEC_CLEAR = 'CLEAR'

export const CAPTION_INSERT = '新規登録'
export const CAPTION_UPDATE = '更新'
export const CAPTION_DELETE = '削除'

export const MAIL_TEMPLATE_1 = '予約新規登録：管理者へ'
export const MAIL_TEMPLATE_2 = '予約新規登録：契約社へ'
export const MAIL_TEMPLATE_3 = '予約更新：管理者へ'
export const MAIL_TEMPLATE_4 = '予約更新：契約社へ'
export const MAIL_TEMPLATE_5 = '予約取り消し：管理者へ'
export const MAIL_TEMPLATE_6 = '予約取り消し：契約社へ'

export const MAIL_TEMPLATES: { [key: number]: string } = {
  1: MAIL_TEMPLATE_1,
  2: MAIL_TEMPLATE_2,
  3: MAIL_TEMPLATE_3,
  4: MAIL_TEMPLATE_4,
  5: MAIL_TEMPLATE_5,
  6: MAIL_TEMPLATE_6
}

export const UNIT_TO_TIME_ARRAY: ListElement[] = [
  { value: 0, label: '0:00' },
  { value: 1, label: '0:30' },
  { value: 2, label: '1:00' },
  { value: 3, label: '1:30' },
  { value: 4, label: '2:00' },
  { value: 5, label: '2:30' },
  { value: 6, label: '3:00' },
  { value: 7, label: '3:30' },
  { value: 8, label: '4:00' },
  { value: 9, label: '4:30' },
  { value: 10, label: '5:00' },
  { value: 11, label: '5:30' },
  { value: 12, label: '6:00' },
  { value: 13, label: '6:30' },
  { value: 14, label: '7:00' },
  { value: 15, label: '7:30' },
  { value: 16, label: '8:00' }
]

export const TIME_LIMIT_ARRAY: ListElement[] = [
  { value: 1, label: '0:30' },
  { value: 2, label: '1:00' },
  { value: 3, label: '1:30' },
  { value: 4, label: '2:00' },
  { value: 5, label: '2:30' },
  { value: 6, label: '3:00' },
  { value: 7, label: '3:30' },
  { value: 8, label: '4:00' },
  { value: 9, label: '4:30' },
  { value: 10, label: '5:00' },
  { value: 11, label: '5:30' },
  { value: 12, label: '6:00' },
  { value: 13, label: '6:30' },
  { value: 14, label: '7:00' },
  { value: 15, label: '7:30' },
  { value: 16, label: '8:00' }
]

// export const TIME_TO_UNIT_ARRAY: TimeToUnit[] = [
//   { time: '0:00', unit: 0 },
//   { time: '0:00', unit: 0 },
//   { time: '1:00', unit: 2 },
//   { time: '1:00', unit: 2 },
//   { time: '2:00', unit: 4 },
//   { time: '2:00', unit: 4 },
//   { time: '3:00', unit: 6 },
//   { time: '3:00', unit: 6 },
//   { time: '4:00', unit: 8 },
//   { time: '4:00', unit: 8 },
//   { time: '5:00', unit: 10 },
//   { time: '5:00', unit: 10 },
//   { time: '6:00', unit: 12 },
//   { time: '6:00', unit: 12 },
//   { time: '7:00', unit: 14 },
//   { time: '7:00', unit: 14 },
//   { time: '8:00', unit: 16 },
//   { time: '8:00', unit: 16 },
//   { time: '9:00', unit: 18 },
//   { time: '9:00', unit: 18 },
//   { time: '10:00', unit: 20 },
//   { time: '10:00', unit: 20 },
//   { time: '11:00', unit: 22 },
//   { time: '10:00', unit: 20 },
//   { time: '12:00', unit: 24 },
//   { time: '10:00', unit: 20 },
//   { time: '13:00', unit: 26 },
//   { time: '10:00', unit: 20 },
//   { time: '14:00', unit: 28 },
//   { time: '10:00', unit: 20 },
//   { time: '15:00', unit: 30 },
//   { time: '16:00', unit: 32 },
//   { time: '17:00', unit: 34 },
//   { time: '18:00', unit: 36 },
//   { time: '19:00', unit: 38 },
//   { time: '20:00', unit: 40 },
//   { time: '21:00', unit: 42 },
//   { time: '22:00', unit: 44 },
//   { time: '23:00', unit: 46 }
// ]

export const DISPLAY_UNIT_ARRAY: ListElement[] = [
  { value: 7, label: '週間表示' },
  { value: 14, label: '２週間表示' },
  { value: 30, label: '月間表示' }
]

export const USER_TYPE_MAP: { [key: number]: string } = {
  1: '法人',
  2: '個人事業主',
  9: '管理者'
}

// Date.getDay()の結果から、和暦の曜日文字を取得する配列
export const WEEKDAY_CAPTIONS = ['日', '月', '火', '水', '木', '金', '土']

export const INDEX_TO_TIME_MAP: string[] = []
const initMap = () => {
  let minute: string[] = ['00', '30']
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
      INDEX_TO_TIME_MAP.push(i.toString() + ':' + minute[j] + ' ~')
    }
  }
}
initMap()
