/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, createContext, useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'

import { registerReservation, removeReservation, updateReservation } from 'api/reservations'
import { getFacilityList, getFacilityMst, getTimeUnitsFor } from 'api/common/masterCache'
import type { ListElement } from 'types/common/ListElement'
import { ProviderProps } from 'types/common/ProviderProps'
import { ReservationInfo } from 'types/infos/ReservationInfo'
import { hasNotValue, hasValue, invalidMailAddress, invalidMaxLength } from 'utils/validators'
import {
  COMMON_ERROR_MESSAGE,
  CONTAIN_ERROR_TITLE,
  emailRegexpMessage,
  FATAL_ERROR_MESSAGE,
  FATAL_ERROR_TITLE,
  getConfirmMessage,
  getConfirmTitle,
  getNotifyMessage,
  getNotifyTitle,
  lengthLessThanMessage,
  requiredMessage
} from 'utils/messageUtil'
import {
  calcEndTime,
  currentDatetime,
  getEndTime,
  nearestDatetime,
  retrieveTime,
  toWareki
} from 'utils/dateUtil'
import { EXEC_INSERT, EXEC_UPDATE, EXEC_DELETE, RESPONSE_OK, TIME_LIMIT_ARRAY } from 'utils/constants'
import { EXEC_TYPE } from 'types/common/ExecType'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import { ReservationInsertInfo, ReservedInfo } from 'types/ui/ReservedInfo'
import { ReserverInfo } from 'types/infos/ContractorInfo'
import { findReserverAll } from 'api/contractors'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { FacilityMaster } from 'types/infos/FacilityMaster'

/**
 *
 */
interface StateProps {
  facilities: ListElement[]
  timeUnits: ListElement[]
  userCountList: ListElement[]
  reserverList: ListElement[]

  reserver: ReserverInfo

  isProxy: boolean
  reserverId: number
  facilityId: number
  facilityDetail: string
  facilityLimitTime: number
  userCount: number
  reservationDatetime: Date | null
  timeUnitString: string
  applicant: string
  replyEmail: string
  overview: string
  contact: string
  // 導出プロパティ
  reservationDate: string
  startTime: string
  endTime: string
  isInsert: boolean
  isDisplayProxy: boolean
  isShowDetail: boolean

  msg4ReserverId: string
  msg4FacilityId: string
  msg4UserCount: string
  msg4ReservationDatetime: string
  msg4TimeUnit: string
  msg4Applicant: string
  msg4ReplyEmail: string
  msg4Overview: string
  msg4Contact: string

  init: (initParam: ReservationInsertInfo) => void
  refresh: (info: ReservedInfo) => void
  setDialogModeOnOpen: (mode: EXEC_TYPE) => void

  clearReservationId: () => void
  initReserver: () => void
  changeIsProxy: (val: boolean) => void
  changeReserverId: (val: number) => void
  changeFacilityId: (val: number) => void
  changeUserCount: (val: number) => void
  changeReservationDatetime: (val: Date | null) => void
  changeTimeUnit: (val: string) => void
  changeApplicant: (val: string | null) => void
  changeReplyEmail: (val: string | null) => void
  changeOverview: (val: string | null) => void
  changeContact: (val: string | null) => void

  // ダイアログ属性
  dialogTitle: string
  dialogContents: string
  // ダイアログ表示制御
  showNotifyDialog: boolean
  showConfirmDialog: boolean
  showErrorDialog: boolean
  //
  closeNotifyDialog: () => void
  closeConfirmDialog: () => void
  closeErrorDialog: () => void

  insert: () => void
  doInsert: () => void
  update: () => void
  doUpdate: () => void
  remove: () => void
  doRemove: () => void
  clear: () => void
  doCallback: () => Promise<void>
}

/**
 *
 */
export const ReservationDialogStateContext = createContext({} as StateProps)

/**
 *
 * @returns
 */
export const useReservationDialogState = () => useContext(ReservationDialogStateContext)

/**
 *
 * @param props
 * @returns
 */
export const ReservationDialogStateProvider = (props: ProviderProps) => {
  //
  const { children } = props

  const loginStates = useLoginState()
  const gState = useGlobalState()

  // 施設ドロップダウンの選択リスト
  const [facilities, setFacilities] = useState<ListElement[]>([])
  const [facilityMasters, setFacilityMasters] = useState<FacilityMaster[]>([])
  const [timeUnits, setTimeUnits] = useState<ListElement[]>([])
  const [userCountList, setUserCountList] = useState<ListElement[]>([])
  const [reserverList, setReserverList] = useState<ListElement[]>([])

  // いわゆる隠し項目
  const [reservationId, setReservationId] = useState<number | null>(null)
  const [contractorId, setContractorId] = useState(0)
  // const [originalReservation, setOriginalReservation] = useState<ReservedInfo | null>(null)
  const [dialogMode, setDialogMode] = useState<EXEC_TYPE>(EXEC_INSERT)
  // ここまで

  // 管理者が契約社の代理登録などを行う場合、成り代わって登録する対象の契約社
  const [reserver, setReserver] = useState<ReserverInfo | null>(null)
  //
  const [reservers, setReservers] = useState<ReserverInfo[]>([])

  // 画面連携項目
  const [isProxy, setIsProxy] = useState<boolean>(false)
  const [reserverId, setReserverId] = useState<number>(0)
  const [facilityId, setFacilityId] = useState<number>(0)
  const [facilityDetail, setFacilityDetail] = useState<string>('')
  const [facilityLimitTime, setFacilityLimitTime] = useState<number>(0)
  const [userCount, setUserCount] = useState<number>(0)
  const [reservationDatetime, setReservationDatetime] = useState<Date | null>(currentDatetime(true))
  const [timeUnit, setTimeUnit] = useState<number>(-1)
  const [endDatetime, setEndDatetime] = useState<Date | null>(null)
  const [applicant, setApplicant] = useState<string | null>(null)
  const [replyEmail, setReplyEmail] = useState<string>('')
  const [overview, setOverview] = useState<string | null>(null)
  const [contact, setContact] = useState<string | null>(null)

  // メッセージ系
  const [msg4ReserverId, setMsg4ReserverId] = useState<string>('')
  const [msg4FacilityId, setMsg4FacilityId] = useState<string>('')
  const [msg4UserCount, setMsg4UserCount] = useState<string>('')
  const [msg4ReservationDatetime, setMsg4ReservationDatetime] = useState<string>('')
  const [msg4TimeUnit, setMsg4TimeUnit] = useState<string>('')
  const [msg4Applicant, setMsg4Applicant] = useState<string>('')
  const [msg4ReplyEmail, setMsg4ReplyEmail] = useState<string>('')
  const [msg4Overview, setMsg4Overview] = useState<string>('')
  const [msg4Contact, setMsg4Contact] = useState<string>('')

  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showNotifyDialog, setShowNotifyDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const [isDisplayProxy, setIsDisplayProxy] = useState<boolean>(false)

  // --【導出プロパティ】------------------------------------------
  const reservationDate = reservationDatetime ? toWareki(reservationDatetime) : ''
  const startTime = reservationDatetime ? retrieveTime(reservationDatetime) : ''
  const endTime = reservationDatetime ? getEndTime(reservationDatetime, timeUnit) ?? '' : ''

  // --【導出State】------------------------------------------
  const timeUnitString = timeUnit.toString()
  const isInsert = dialogMode === EXEC_INSERT
  const isAdmin = loginStates.isAdmin()
  const isShowDetail = facilityId !== 0

  // --【初期表示処理】------------------------------------------
  /**
   * 画面初期表示時
   */
  useEffect(() => {
    const func = async () => {
      try {
        // 施設プルダウンリストの取得
        const ret = await getFacilityList()
        setFacilities(ret)
        // 施設詳細情報リストの取得 -> データの持ち方は要検討
        const details = await getFacilityMst()
        setFacilityMasters(details)

        // 管理者は、代理操作が可能。代理する契約社の一覧を取得しておく。
        if (isAdmin) {
          const list = await findReserverAll()
          setReservers(list)
          const elements: ListElement[] = list.map((e: ReserverInfo) => {
            return {
              value: e.contractorId,
              label: `${e.roomNo} : ${e.contractorName}`
            }
          })
          setReserverList(elements)
        }
        setReserver(getLoginUserForReserver())
      } catch (e: unknown) {
        // SessionTimeout処理
        if (gState.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      }
    }

    // 使用人数ドロップダウンリスト
    setUserCountList([
      { value: 1, label: '１人' },
      { value: 2, label: '２人' },
      { value: 3, label: '３人' },
      { value: 4, label: '４人' },
      { value: 5, label: '５人' },
      { value: 6, label: '６人' },
      { value: 7, label: '７人' },
      { value: 8, label: '８人' }
    ])

    func()
  }, [])

  useEffect(() => {
    // 「予約者」情報に当該契約社の情報を出力
    changeReserverId(contractorId)
  }, [contractorId])

  /**
   *
   */
  useEffect(() => {
    clearTimeUnitIfNotExistInList()
  }, [reservationDatetime, timeUnits])

  /**
   * 予約日時、予約時間数の変更時
   */
  useEffect(() => {
    setEndDatetime(calcEndTime(reservationDatetime, timeUnit))
    if (reservationDatetime === null) return
    // 当日の最終時刻までで切り捨てる
    var units = getTimeUnitsFor(reservationDatetime)
    // 施設の予約時間上限で切り捨てる
    if (units.length > facilityLimitTime) {
      units = units.slice(0, facilityLimitTime)
    }
    setTimeUnits(units)
  }, [reservationDatetime, timeUnit])

  /**
   * 予約日時（開始）が変更された時（それにより予約時間数ドロップダウンのリストが変更されることがある）、リストに存在して
   * いない時間数を選択していた場合は、最後の時間数（最大の時間数 = 1日の終わりまで）に更新する。
   * 日跨ぎで予約時間を設定することを抑制するため。
   */
  const clearTimeUnitIfNotExistInList = () => {
    const notExistTimeUnitInList = timeUnits.findIndex((u) => u.value === timeUnit) === -1
    if (notExistTimeUnitInList) {
      if (timeUnits.length === 0) return
      if (timeUnit === 0) return
      const lastUnit = timeUnits[timeUnits.length - 1].value as number
      setTimeUnit(lastUnit)
    }
  }

  /**
   * ダイアログ内のフォームの情報を新規登録用にクリアする。
   * @param param
   */
  const init = (initParam: ReservationInsertInfo): void => {
    // 一旦全クリア
    clearValues()
    // 予約者をログインユーザーで初期化
    initReserver()
    // クリックされたセルの施設・予約開始時刻、デフォルトの予約時間数で初期化
    changeFacilityId(initParam.facilityId)
    changeReservationDatetime(initParam.reservationDatetime)
    changeTimeUnit(initParam.timeUnit!.toString())
    // 管理者の場合、代理予約欄をオープンする
    setIsDisplayProxy(isAdmin)
    // 「契約社の代理登録」はデフォルトでOFF
    setIsProxy(false)
  }

  /**
   * ダイアログ内のフォームの情報を既存施設予約の更新・削除用に更新する。
   * @param reservedInfo
   */
  const refresh = (reservedInfo?: ReservedInfo): void => {
    // ダイアログがcloseされるタイミングでもコールされる。その時は引数がundefinedになっている
    if (!reservedInfo) {
      return
    }

    // 一旦全クリア
    clearValues()
    // 渡された予約情報を保持しておく
    const info = cloneDeep(reservedInfo)
    // setOriginalReservation(info)

    setReservationId(info.reservationId)
    setContractorId(info.contractorId)
    setFacilityId(info.facilityId)
    setFacilityDetail(info.detail)
    setUserCount(info.userCount)
    setReservationDatetime(info.reservationDatetime)
    setTimeUnit(info.timeUnit)
    setApplicant(info.applicant)
    setReplyEmail(info.replyEmail)
    setOverview(info.overview)
    setContact(info.contact)
    // 更新・削除時は、予約者を代理で変更する用途はないはず。
    setIsDisplayProxy(false)
  }

  const setDialogModeOnOpen = (mode: EXEC_TYPE) => {
    setDialogMode(mode)
  }

  /**
   * ログインユーザーを「予約者」とする場合の契約社情報を取得する。
   * （デフォルト）
   * @returns
   */
  const getLoginUserForReserver = (): ReserverInfo => {
    return {
      contractorId: loginStates.loginContractorId!,
      contractorName: loginStates.loginContractorName!,
      roomNo: loginStates.loginRoomNo!,
      name: loginStates.loginName!,
    }
  }
  // --【change イベントハンドラ】------------------------------------------

  const clearReservationId = () => {
    setReservationId(null)
  }

  // 予約者をログイン契約社で初期化する
  const initReserver = () => {
    setReserverId(loginStates.loginContractorId!)
    setReserver(getLoginUserForReserver())
  }

  // 契約社の代理登録？
  const changeIsProxy = (val: boolean) => {
    setIsProxy(val)
    if (val === false) {
      setReserverId(loginStates.loginContractorId!)
      setReserver(getLoginUserForReserver())
    }
  }

  // 予約者ID
  const changeReserverId = (val: number) => {
    setReserverId(val)
    if (hasValue(val)) setMsg4ReserverId(() => '')
    const reserver = reservers.find((r) => r.contractorId === val)
    setReserver(reserver ? reserver : getLoginUserForReserver())
  }

  // 施設ID
  const changeFacilityId = (val: number) => {
    setFacilityId(() => val)
    if (hasValue(val)) setMsg4FacilityId(() => '')

    // 施設詳細と施設利用時間上限を更新
    const mst = facilityMasters.find((d) => d.facilityId === val)
    const facilityDetail = val === 0 || mst === null || mst?.detail === null ? '' : mst!.detail
    setFacilityDetail(facilityDetail)
    const facilityLimitTime = val === 0 || mst === null ? 0 : mst!.timeLimit
    setFacilityLimitTime(facilityLimitTime)

    // 予約時間単位ドロップダウンを更新
    const newTimeUnits = TIME_LIMIT_ARRAY.filter((tl) => tl.value as number <= facilityLimitTime)
    setTimeUnits(newTimeUnits)
  }

  // 使用人数
  const changeUserCount = (val: number) => {
    setUserCount(() => val)
    if (hasValue(val)) setMsg4UserCount(() => '')
  }

  // 予約月日
  const changeReservationDatetime = (val: Date | null) => {
    setReservationDatetime(() => val)
    if (hasValue(val)) setMsg4ReservationDatetime(() => '')
  }

  //
  const changeTimeUnit = (val: string) => {
    // if (hasNotValue(val)) {
    // setTimeUnit(() => -1)
    // } else {
    const numValue = parseInt(val, 10)
    setTimeUnit(() => numValue)
    // }

    if (hasValue(val)) setMsg4TimeUnit(() => '')
  }

  // 申請者
  const changeApplicant = (val: string) => {
    setApplicant(() => val)
    if (hasValue(val)) setMsg4Applicant(() => '')
  }

  // 返信メールアドレス
  const changeReplyEmail = (val: string) => {
    setReplyEmail(() => val)
    if (hasValue(val)) setMsg4ReplyEmail(() => '')
  }

  // 予約概要
  const changeOverview = (val: string) => {
    setOverview(() => val)
    if (hasValue(val)) setMsg4Overview(() => '')
  }

  // 管理者への連絡事項
  const changeContact = (val: string) => {
    setContact(() => val)
    if (hasValue(val)) setMsg4Contact(() => '')
  }

  // --【click イベントハンドラ】------------------------------------------

  /**
   * 新規登録
   * @returns
   */
  const insert = () => {
    try {
      if (checkAll() === false) {
        showErrorMessage()
        return
      }
      setExecType(EXEC_INSERT)
      showConfirmMessage(EXEC_INSERT)
    } catch (e) {
      console.log(e)
    }
  }
  const doInsert = async () => {
    try {
      const params = createRequestParams()
      const data = await registerReservation(params)
      if (data.status === RESPONSE_OK) {
        showNotifyMessage(EXEC_INSERT)
        return
      }
      // エラー発生時
      const title = 'エラー'
      const message = data.msgs![0].message ?? '予期せぬエラー'
      //
      if (data.code === 504) {
        setMsg4ReservationDatetime(message)
        setMsg4TimeUnit(message)
      }
      setDialogTitle(title)
      setDialogContents(message)
      setShowErrorDialog(true)
    } catch (e: unknown) {
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  /**
   * 更新
   * @returns
   */
  const update = async () => {
    if (checkAll() === false) {
      showErrorMessage()
      return
    }

    setExecType(EXEC_UPDATE)
    showConfirmMessage(EXEC_UPDATE)
  }
  const doUpdate = async () => {
    try {
      const params = createRequestParams()
      await updateReservation(params)
      showNotifyMessage(EXEC_UPDATE)
    } catch (e: unknown) {
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  /**
   * 削除
   * @returns
   */
  const remove = async () => {
    setExecType(EXEC_DELETE)
    showConfirmMessage(EXEC_DELETE)
  }
  const doRemove = async () => {
    try {
      await removeReservation(reservationId!)
      showNotifyMessage(EXEC_DELETE)
    } catch (e: unknown) {
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  /**
   * クリア
   */
  const clear = () => {
    if (dialogMode === EXEC_INSERT) {
      clearValues()
    } else {
      refresh()
    }
    clearMsgs()
  }

  const doCallback = async () => {
    if (!execType) throw new Error('実行種別が指定されていません。')

    if (execType === EXEC_INSERT) {
      await doInsert()
    }
    if (execType === EXEC_UPDATE) {
      await doUpdate()
    }
    if (execType === EXEC_DELETE) {
      await doRemove()
    }
  }

  // ダイアログ定義
  /**
   * エラーメッセージを表示する
   */
  const showErrorMessage = (): void => {
    setDialogTitle(CONTAIN_ERROR_TITLE)
    setDialogContents(COMMON_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 確認メッセージを表示する
   * @param type
   */
  const showConfirmMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getConfirmTitle(type))
    setDialogContents(getConfirmMessage(type, '予約情報'))
    setShowConfirmDialog(true)
  }

  /**
   * 通知メッセージを表示する
   * @param type
   */
  const showNotifyMessage = (type: EXEC_TYPE): void => {
    setDialogTitle(getNotifyTitle(type))
    setDialogContents(getNotifyMessage(type, '予約情報'))
    setShowNotifyDialog(true)
  }

  /**
   * エラーダイアログを消去する
   */
  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 確認ダイアログを消去する
   */
  const closeConfirmDialog = () => {
    setShowConfirmDialog(false)
  }

  /**
   * 通知ダイアログを消去する
   */
  const closeNotifyDialog = () => {
    setShowNotifyDialog(false)
  }
  // -- PRIVATE 処理用 ------------------------------------------

  // --【共通処理】------------------------------------------
  /**
   * 入力値のクリア
   */
  const clearValues = () => {
    setFacilityId(0)
    setFacilityDetail('')
    setUserCount(0)
    setReservationDatetime(nearestDatetime(new Date()))
    setApplicant('')
    setReplyEmail('')
    setOverview('')
    setContact('')
    setTimeUnit(2)
  }

  /**
   * エラーメッセージのクリア
   */
  const clearMsgs = () => {
    setMsg4FacilityId('')
    setMsg4UserCount('')
    setMsg4ReservationDatetime('')
    setMsg4Applicant('')
    setMsg4ReplyEmail('')
    setMsg4Overview('')
    setMsg4Contact('')
  }

  /**
   * サーバー通信APIパラメータを生成する
   * @returns
   */
  const createRequestParams = (): ReservationInfo => {
    const endDatetime = calcEndTime(reservationDatetime, timeUnit)
    return {
      reservationId,
      userCount,
      contractorId: reserver?.contractorId!,
      facilityId,
      reservationDatetime,
      timeUnit,
      endDatetime,
      applicant,
      replyEmail,
      overview,
      contact
    }
  }

  // --【バリデータ】------------------------------------------

  /**
   * 全項目をチェックする
   * @returns
   */
  const checkAll = (): boolean => {
    const allResult = [] as boolean[]
    allResult.push(checkFacilityId())
    allResult.push(checkUserCount())
    allResult.push(checkReservationDatetime())
    allResult.push(checkTimeUnit())
    allResult.push(checkApplicant())
    allResult.push(checkReplyEmail())
    allResult.push(checkOverview())
    allResult.push(checkContact())
    return allResult.includes(false) === false
  }

  /**
   * 施設名選択チェック
   * @returns
   */
  const checkFacilityId = (): boolean => {
    // 必須入力
    if (hasNotValue(facilityId)) {
      setMsg4FacilityId(requiredMessage('施設名'))
      return false
    }
    return true
  }

  const checkUserCount = (): boolean => {
    // 必須入力
    if (hasNotValue(userCount)) {
      setMsg4UserCount(requiredMessage('使用人数'))
      return false
    }
    return true
  }

  // 予約日時
  const checkReservationDatetime = (): boolean => {
    // 必須入力
    if (hasNotValue(reservationDatetime)) {
      setMsg4ReservationDatetime(requiredMessage('予約開始日時'))
      return false
    }
    //
    if (reservationDatetime! < nearestDatetime(new Date())) {
      setMsg4ReservationDatetime('予約可能時間帯を経過しています')
      return false
    }
    return true // TODO
  }
  // 予約時間数
  const checkTimeUnit = (): boolean => {
    // 必須入力
    if (hasNotValue(timeUnit)) {
      setMsg4TimeUnit(requiredMessage('予約時間数'))
      return false
    }
    return true // TODO
  }

  // 申請者
  const checkApplicant = (): boolean => {
    // 必須入力 -> 担当者をデフォルトで設定する
    if (hasNotValue(applicant)) return true
    // 桁数チェック
    if (invalidMaxLength(applicant!, 50)) {
      setMsg4Applicant(lengthLessThanMessage('申請者', 50))
      return false
    }

    return true
  }

  // 返信メールアドレス
  const checkReplyEmail = (): boolean => {
    // 必須入力チェック
    if (hasNotValue(replyEmail)) {
      setMsg4ReplyEmail(requiredMessage('返信メールアドレス'))
      return false
    }
    // 桁数チェック
    // フォーマットチェック
    if (invalidMailAddress(replyEmail!)) {
      setMsg4ReplyEmail(() => emailRegexpMessage())
      return false
    }

    return true
  }

  // 予約概要
  const checkOverview = (): boolean => {
    if (hasNotValue(overview)) return true
    // 上記でチェック済だが、ソース上のnull可能性エラーを抑止するため。
    if (overview === null) return true

    // 桁数チェック
    if (invalidMaxLength(overview, 200)) {
      setMsg4Overview(lengthLessThanMessage('予約概要', 200, overview.length))
      return false
    }

    return true
  }
  // 連絡事項
  const checkContact = (): boolean => {
    if (hasNotValue(contact)) return true
    // 上記でチェック済だが、ソース上のnull可能性エラーを抑止するため。
    if (contact === null) return true

    // 桁数チェック
    if (invalidMaxLength(contact, 800)) {
      setMsg4Contact(lengthLessThanMessage('連絡事項', 800, contact.length))
      return false
    }

    return true
  }

  // --【表示処理】------------------------------------------

  const globalStates = {
    facilities,
    timeUnits,
    userCountList,
    reserverList,

    reserver,

    isProxy,
    reserverId,
    facilityId,
    facilityDetail,
    facilityLimitTime,
    userCount,
    reservationDatetime,
    timeUnitString,
    applicant,
    replyEmail,
    overview,
    contact,
    //
    reservationDate,
    startTime,
    endTime,
    isInsert,
    isDisplayProxy,
    isShowDetail,

    msg4FacilityId,
    msg4UserCount,
    msg4ReservationDatetime,
    msg4TimeUnit,
    msg4Applicant,
    msg4ReplyEmail,
    msg4Overview,
    msg4Contact,
    msg4ReserverId,

    init,
    refresh,
    setDialogModeOnOpen,

    clearReservationId,
    initReserver,
    changeIsProxy,
    changeReserverId,
    changeFacilityId,
    changeUserCount,
    changeReservationDatetime,
    changeTimeUnit,
    changeApplicant,
    changeReplyEmail,
    changeOverview,
    changeContact,
    //
    dialogTitle,
    dialogContents,
    showNotifyDialog,
    showConfirmDialog,
    showErrorDialog,
    closeNotifyDialog,
    closeConfirmDialog,
    closeErrorDialog,

    insert,
    doInsert,
    update,
    doUpdate,
    remove,
    doRemove,
    clear,
    doCallback
  } as StateProps

  return (
    <ReservationDialogStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </ReservationDialogStateContext.Provider>
  )
}
