import { useState } from 'react'

import PageTitle from 'components/common/PageTitle'
import ContractorDialog from 'components/common/dialogs/ContractorDialog'
import { ContractorStateProvider } from 'components/contractors/contractor/ContractorStateProvider'
import { useContractorsState } from 'components/contractors/contractors/ContractorsStateProvider'
import styles from 'components/contractors/contractors/ContractorsForm.module.scss'
import tStyles from 'styles/utils/table.module.scss'
import type { ContractorInfo } from 'types/infos/ContractorInfo'
import { toWarekiDate } from 'utils/dateUtil'
import { EXEC_INSERT, EXEC_UPDATE, USER_TYPE_MAP } from 'utils/constants'
import { EXEC_TYPE } from 'types/common/ExecType'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

/**
 *
 * @returns
 */
const ContractorsForm = () => {
  //
  const states = useContractorsState()

  // States
  const [open, setOpen] = useState<boolean>(false)
  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)

  const selectContractor = (contractor: ContractorInfo) => {
    setExecType(EXEC_UPDATE)
    states.selectContractor(contractor)
    openDialog()
  }

  const showDialogForCreate = () => {
    setExecType(EXEC_INSERT)
    openDialog()
  }

  const openDialog = () => {
    setOpen(() => true)
  }

  const closeDialog = () => {
    states.refresh()
    setOpen(() => false)
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  const toDisplayRoomNo = (roomNo: string) => {
    return roomNo === 'admin' ? '---' : roomNo
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="契約社一覧"></PageTitle>
      <div className={tStyles.tablePreInfo}>
        <div></div>
        <div className={tStyles.tablePreInfo}>
          <div></div>
          <button className={tStyles.insertButton} onClick={showDialogForCreate}>
            契約社新規登録
          </button>
        </div>
      </div>
      <div className={tStyles.tableFrame}>
        <div className={tStyles.commonTable}>
          <div className={tStyles.headerRow}>
            <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
            <div className={`${tStyles.headerColumn} ${styles.col2}`}>部屋</div>
            <div className={`${tStyles.headerColumn} ${styles.col3}`}>企業名</div>
            <div className={`${tStyles.headerColumn} ${styles.col4}`}>利用開始日</div>
            <div className={`${tStyles.headerColumn} ${styles.col5}`}>利用終了日</div>
            <div className={`${tStyles.headerColumn} ${styles.col6}`}>種別</div>
            <div className={`${tStyles.headerColumn} ${styles.col7}`}>担当者名</div>
          </div>
          {states.contractorList.map((c: ContractorInfo, index) => (
            <div
              className={tStyles.dataRow}
              key={c.contractorId}
              onClick={() => selectContractor(c)}
            >
              <div className={`${tStyles.dataColumn} ${styles.col1}`}>{index + 1}</div>
              <div className={`${tStyles.dataColumn} ${styles.col2}`}>
                {toDisplayRoomNo(c.roomNo)}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col3}`}>{c.contractorName}</div>
              <div className={`${tStyles.dataColumn} ${styles.col4}`}>
                {toWarekiDate(new Date(c.usageStartDate!))}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col5}`}>
                {c.usageEndDate && toWarekiDate(new Date(c.usageEndDate))}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col6}`}>
                {USER_TYPE_MAP[c.coopType]}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col7}`}>{c.name}</div>
            </div>
          ))}
          {/* 契約社一覧は、管理者レコードが常に存在し削除することができないため、空白になることはない */}
        </div>
      </div>

      {/* ==== 契約社登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <ContractorStateProvider>
        <ContractorDialog
          execType={execType}
          contractorInfo={states.targetContractor!}
          show={open}
          onClose={closeDialog}
        />
      </ContractorStateProvider>
      {/* ここまで */}
    </>
  )
}

export default ContractorsForm
