import { useEffect, useState } from 'react'

import TextArea from 'components/common/atoms/TextArea'
import ConfirmDialog from 'components/common/dialogs/messages/ConfirmDialog'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import NotifyDialog from 'components/common/dialogs/messages/NotifyDialog'
import CheckBox from 'components/common/atoms/CheckBox'
import fStyles from 'styles/utils/form.module.scss'
import { useReservationSuspendState } from './ReservationSuspendStateProvider'
import DropDown from 'components/common/atoms/DropDown'
import D1pDatePicker from 'components/common/atoms/D1pDatePicker'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { ReservationSuspendListElement } from 'types/ui/ReservationSuspendListElement'
import Label from 'components/common/atoms/Label'
import { useWindowDimension } from 'utils/windowUtils'

interface ReservationSuspendFormProps {
  execType: EXEC_TYPE
  doClose?: () => void
  suspendInfo?: ReservationSuspendListElement
}

const ReservationSuspendForm = (props: ReservationSuspendFormProps) => {
  //
  const { execType, suspendInfo, doClose } = props
  //
  const states = useReservationSuspendState()
  const [winWidth] = useWindowDimension()
  const [textareaCols, setTextareaCols] = useState<number>(0)

  const isInsert = execType === EXEC_INSERT
  const isUpdate = execType === EXEC_UPDATE

  // 画面初期化
  useEffect(() => {
    // 新規登録時
    if (execType === EXEC_INSERT) {
      states.refresh(execType)
      return
    }

    // 更新時
    if (execType === EXEC_UPDATE) {
      states.refresh(execType, suspendInfo!)
    }
  }, [])

  useEffect(() => {
    setTextareaCols(50)
  }, [winWidth])

  /**
   *
   */
  const doOnConfirmOk = async () => {
    await states.doCallback()
  }
  /**
   *
   */
  const doOnConfirmCancel = () => {
    states.closeConfirmDialog()
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }
  /**
   *
   */
  const closeNotifyDialog = () => {
    states.closeNotifyDialog()
    doClose!()
  }

  return (
    <>
      <ConfirmDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showConfirmDialog}
        onOk={doOnConfirmOk}
        onCancel={doOnConfirmCancel}
      />
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <NotifyDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showNotifyDialog}
        onOk={closeNotifyDialog}
      />

      <section className={fStyles.detailForm}>
        <div className={fStyles.topMargin} />
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="施設名" required></Label>
          </span>
          <span className={fStyles.input}>
            <CheckBox
              id="isAllFacilities"
              label="全ての施設を停止"
              checked={states.isAllFacilities}
              onChange={(event) => states.changeIsAllFacilities(event.target.checked)}
            />
          </span>
        </div>
        <div className={`${fStyles.row} ${fStyles.breakLine}`}>
          <span className={fStyles.label}></span>
          <span className={fStyles.input}>
            <DropDown
              id="targetFacilityId"
              size={300}
              showEmptyOption
              list={states.facilitiesList}
              value={states.facilityIdString}
              onChange={(event) => states.changeFacilityId(parseInt(event.target.value, 10))}
              errorMsg={states.msg4FacilityId}
              disabled={states.isAllFacilities}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="停止予定" required></Label>
          </span>
          <span className={fStyles.input}>
            <CheckBox
              id="isAllDay"
              label="終日停止"
              checked={states.isAllDay}
              onChange={(event) => states.changeIsAllDay(event.target.checked)}
            />
          </span>
        </div>
        <div className={`${fStyles.row} ${fStyles.breakLine}`}>
          <span className={fStyles.label}></span>
          {states.isAllDay && (
            <span className={fStyles.input}>
              <D1pDatePicker
                id="suspendAllDatetime"
                months={2}
                clearButton
                notShownPast
                selected={states.suspendAllDatetime}
                onChange={(event) => states.changeSuspendAllDatetime(event)}
                errorMsg={states.msg4SuspendAllDatetime}
              />
            </span>
          )}
          {states.isAllDay === false && (
            <div className={fStyles.input}>
              <D1pDatePicker
                id="suspendStartDatetime"
                showTime
                months={2}
                clearButton
                notShownPast
                selected={states.suspendStartDatetime}
                onChange={(event) => states.changeSuspendStartDatetime(event)}
                errorMsg={states.msg4SuspendStartDatetime}
              />
              <div> 〜 </div>
              <D1pDatePicker
                id="suspendEndDatetime"
                showTime
                months={2}
                clearButton
                notShownPast
                selected={states.suspendEndDatetime}
                onChange={(event) => states.changeSuspendEndDatetime(event)}
                errorMsg={states.msg4SuspendEndDatetime}
              />
            </div>
          )}
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="理由・その他" required></Label>
          </span>
          <span className={fStyles.input}>
            <TextArea
              id="reason"
              cols={textareaCols}
              rows={5}
              value={states.reason}
              onChange={(event) => states.changeReason(event.target.value)}
              errorMsg={states.msg4Reason}
            />
          </span>
        </div>
        <div className={fStyles.footerButtons}>
          {isInsert && (
            <button className={fStyles.button} onClick={states.insert}>
              新規登録
            </button>
          )}
          {isUpdate && (
            <button className={fStyles.button} onClick={states.update}>
              更新
            </button>
          )}
          <button className={`${fStyles.button} ${fStyles.clear}`} onClick={states.clear}>
            クリア
          </button>
          {isUpdate && (
            <button className={`${fStyles.button} ${fStyles.delete}`} onClick={states.remove}>
              停止情報削除
            </button>
          )}
        </div>
      </section>
    </>
  )
}

export default ReservationSuspendForm
