import fStyles from 'styles/utils/form.module.scss'
// import tStyles from 'styles/utils/table.module.scss'
// import styles from './FacilityForm.module.scss'
import { useFacilityState } from './FacilityStateProvider'
import ConfirmDialog from 'components/common/dialogs/messages/ConfirmDialog'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import NotifyDialog from 'components/common/dialogs/messages/NotifyDialog'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import Label from 'components/common/atoms/Label'
// import { toWareki } from 'utils/dateUtil'
import InputText from 'components/common/atoms/InputText'
import CheckBox from 'components/common/atoms/CheckBox'
import { useEffect } from 'react'
import { FacilityInfo } from 'types/infos/FacilityInfo'
import { useFacilitiesState } from '../facilities/FacilitiesStateProvider'
import TextArea from 'components/common/atoms/TextArea'
import DropDown from 'components/common/atoms/DropDown'

interface FacilityFormProps {
  execType: EXEC_TYPE
  facilityInfo?: FacilityInfo | null
  doClose?: () => void
}

const FacilityForm = (props: FacilityFormProps) => {
  //
  const { execType, facilityInfo, doClose } = props
  //
  const states = useFacilityState()
  const listStates = useFacilitiesState()

  const isInsert = execType === EXEC_INSERT
  const isUpdate = execType === EXEC_UPDATE

  // 画面初期化
  useEffect(() => {
    // 新規登録時
    if (execType === EXEC_INSERT) {
      states.refresh(execType)
      states.changeDisplayOrder(listStates.nextDisplayOrder)
      states.setOrigDisplayOrder(listStates.nextDisplayOrder)
      return
    }

    // 更新時
    if (execType === EXEC_UPDATE) {
      states.refresh(execType, facilityInfo!)
    }
  }, [])

  /**
   *
   */
  const doOnConfirmOk = async () => {
    await states.doCallback()
  }
  /**
   *
   */
  const doOnConfirmCancel = () => {
    states.closeConfirmDialog()
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }
  /**
   *
   */
  const closeNotifyDialog = () => {
    states.closeNotifyDialog()
    doClose!()
  }

  return (
    <>
      <ConfirmDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showConfirmDialog}
        onOk={doOnConfirmOk}
        onCancel={doOnConfirmCancel}
      />
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <NotifyDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showNotifyDialog}
        onOk={closeNotifyDialog}
      />

      <section className={fStyles.detailForm}>
        <div className={fStyles.topMargin} />
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="施設名" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="facilityName"
              size={45}
              maxLength={30}
              value={states.facilityName}
              onChange={(event) => states.changeFacilityName(event.target.value)}
              errorMsg={states.msg4FacilityName}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="フロア" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="floorNum"
              size={2}
              maxLength={2}
              align="right"
              value={states.floorNumString}
              onChange={(event) => states.changeFloorNumString(event.target.value)}
              errorMsg={states.msg4FloorNum}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="並び順" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="displayOrder"
              size={2}
              maxLength={2}
              align="right"
              value={states.displayOrderString}
              onChange={(event) => states.changeDisplayOrderString(event.target.value)}
              errorMsg={states.msg4DisplayOrder}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="施設詳細" />
          </span>
          <span className={fStyles.input}>
          <TextArea
              id="description"
              rows={18}
              cols={60}
              fontSize={16}
              value={states.detail}
              onChange={(event) => states.changeDetail(event.target.value)}
              errorMsg={states.msg4Detail}
            />
          </span>
        </div>
        <div className={`${fStyles.row} ${fStyles.breakLine}`}>
          <span className={fStyles.label}>
            <Label title="予約上限時間" required />
          </span>
          <span className={fStyles.input}>
            <DropDown
              id="targetFacilityId"
              size={100}
              showEmptyOption
              list={states.timeLimits}
              value={states.timeLimitString}
              onChange={(event) => states.changeTimeLimitString(event.target.value)}
              errorMsg={states.msg4TimeLimit}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="公開" />
          </span>
          {/* <span className={fStyles.input}>{states.isAvailable ? '' : '（利用停止中）'}</span> */}
          <CheckBox
            id="isAvailable"
            label="当該施設を使用不可にする場合はチェックを外して下さい"
            noLeftMargin
            checked={states.isAvailable}
            onChange={(event) => states.changeIsAvailable(event.target.checked)}
          />
        </div>

        <div className={fStyles.footerButtons}>
          {isInsert && (
            <button className={fStyles.button} onClick={states.insert}>
              新規登録
            </button>
          )}
          {isUpdate && (
            <button className={fStyles.button} onClick={states.update}>
              更新
            </button>
          )}
          <button className={`${fStyles.button} ${fStyles.clear}`} onClick={states.clear}>
            クリア
          </button>
          {isUpdate && (
            <button className={`${fStyles.button} ${fStyles.delete}`} onClick={states.remove}>
              施設削除
            </button>
          )}
        </div>
      </section>
    </>
  )
}

export default FacilityForm
