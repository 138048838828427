// ユーザー系
// export const USERS_BASE = '/users'

const API_BASE = '/api'
// 契約社系
export const CONTRACTORS_BASE = `${API_BASE}/contractors`
export const LOGIN_URL = `${CONTRACTORS_BASE}/login`
export const LOGOUT_URL = `${CONTRACTORS_BASE}/logout`
export const CHANGE_PASSWORD_URL = `${CONTRACTORS_BASE}/password`
export const RESERVER_ALL_URL = `${CONTRACTORS_BASE}/reservers`
export const CONTRACTOR_URL = `${CONTRACTORS_BASE}`

// オフィス系
export const OFFICES_BASE = `${API_BASE}/offices`
export const OFFICES_ELEMENTS_URL = `${OFFICES_BASE}/elements`

// 施設系
export const FACILITIES_BASE = `${API_BASE}/facilities`
export const FACILITIES_ELEMENTS_URL = `${FACILITIES_BASE}/elements`
export const FACILITIES_MASTERS_URL = `${FACILITIES_BASE}/masters`

// 施設予約系
export const RESERVATIONS_BASE = `${API_BASE}/reservations`
export const RESERVATIONS_FOR_CONTRACTOR = `${RESERVATIONS_BASE}/contractors`
export const RESERVATIONS_FOR_DATE = `${RESERVATIONS_BASE}/date`
export const RESERVATIONS_FOR_FACILITY = `${RESERVATIONS_BASE}/facilities`

// 予約停止系
export const RESERVATION_SUSPENDED_BASE = `${API_BASE}/reservations/suspended`
export const RESERVATION_SUSPENDED_FOR_DATE = `${RESERVATION_SUSPENDED_BASE}/date`
export const RESERVATION_SUSPENDED_FOR_FACILITIES = `${RESERVATION_SUSPENDED_BASE}/facilities`

// 通知系
export const NOTIFICATIONS_BASE = `${API_BASE}/notifications`
export const NOTIFICATIONS_CURRENT = `${NOTIFICATIONS_BASE}/current`
export const NOTIFICATIONS_ALL = `${NOTIFICATIONS_BASE}`

// ダウンロードファイル系
export const DOWNLOAD_FILES_BASE = `${API_BASE}/downloadFiles`
export const DOWNLOAD_FILES_NAMES = `${DOWNLOAD_FILES_BASE}/names`
export const DOWNLOAD_FILES_ATTRS = `${DOWNLOAD_FILES_BASE}/attrs`
export const DOWNLOAD_FILES_FILE = `${DOWNLOAD_FILES_BASE}/file`

// メールテンプレート
export const MAIL_TEMPLATE_BASE = `${API_BASE}/mailTemplates`
