import { createContext, useContext, useEffect, useState } from 'react'

import { findCurrentList, findAllNotifications } from 'api/notifications'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { ProviderProps } from 'types/common/ProviderProps'
import { NotificationRow } from 'types/rows/NotificationRow'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'

const DEFAULT_MAX_COUNT = 50

/**
 *
 */
interface NotificationsState {
  notificationList: NotificationRow[]
  targetNotification: NotificationRow | null
  maxCount: number
  isListEmpty: boolean

  changeMaxCount: (val: number) => void
  selectNotification: (notification: NotificationRow) => void
  refresh: () => void
  showAllNotifications: () => void
  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  closeErrorDialog: () => void
}

/**
 *
 */
export const NotificationsStateContext = createContext({} as NotificationsState)

/**
 *
 * @returns
 */
export const useNotificationsState = () => useContext(NotificationsStateContext)

/**
 *
 * @param props
 * @returns
 */
export const NotificationsStateProvider = (props: ProviderProps) => {
  const { children } = props
  const lStates = useLoginState()
  const gStates = useGlobalState()

  // State
  const [contractorId, setContractorId] = useState<number>(lStates.getContractorId())
  const [maxCount, setMaxCount] = useState<number>(DEFAULT_MAX_COUNT)
  const [notificationList, setNotificationList] = useState<NotificationRow[]>([])
  const [targetNotification, setTargetNotification] = useState<NotificationRow | null>(null)

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  const isListEmpty: boolean = notificationList.length === 0

  useEffect(() => {
    refresh()
  }, [])

  // イベントハンドラ
  /**
   * 表示件数の変更
   * @param val
   */
  const changeMaxCount = (val: number) => {
    setMaxCount(val)
    // 通知の更新
    refresh()
  }

  const selectNotification = (notification: NotificationRow): void => {
    setTargetNotification(notification)
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   *
   */
  const refresh = () => {
    const query = lStates.isAdmin() ? findAllNotifications : findCurrentList 
    query(contractorId)
      .then((list) => {
        setNotificationList(list)
      })
      .catch((e: unknown) => {
        // SessionTimeout処理
        if (gStates.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  /**
   * 
   */
  const showAllNotifications = () => {
    findAllNotifications(contractorId)
      .then((list) => {
        console.log(list)
        setNotificationList(list)
      })
      .catch((e: unknown) => {
        // SessionTimeout処理
        if (gStates.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  const globalStates = {
    notificationList,
    targetNotification,
    isListEmpty,
    maxCount,

    changeMaxCount,
    selectNotification,
    refresh,
    showAllNotifications,
    dialogTitle,
    dialogContents,
    showErrorDialog,
    closeErrorDialog
  }

  return (
    <NotificationsStateContext.Provider value={globalStates}>
      {/*  */}
      {children}
    </NotificationsStateContext.Provider>
  )
}
