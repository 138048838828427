import parse from 'html-react-parser'
import DropDown from 'components/common/atoms/DropDown'
import InputText from 'components/common/atoms/InputText'
import TextArea from 'components/common/atoms/TextArea'
// import { useLoginState } from 'components/common/provider/LoginStateProvider'
import D1pDatePicker from 'components/common/atoms/D1pDatePicker'
import { useReservationDialogState } from './ReservationStateProvider'
import ConfirmDialog from 'components/common/dialogs/messages/ConfirmDialog'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import NotifyDialog from 'components/common/dialogs/messages/NotifyDialog'
import Label from 'components/common/atoms/Label'
import fStyles from 'styles/utils/form.module.scss'
import { useEffect, useState } from 'react'
import { useWindowDimension } from 'utils/windowUtils'
import { EXEC_TYPE } from 'types/common/ExecType'
import CheckBox from 'components/common/atoms/CheckBox'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import { hasValue } from 'utils/validators'

interface Props {
  execType: EXEC_TYPE
  doClose?: () => void
}

/**
 *
 * @returns
 */
const ReservationForm = (props: Props) => {
  const { doClose } = props
  //
  const states = useReservationDialogState()
  const lStates = useLoginState()
  // State
  const [width] = useWindowDimension()
  const [inputWidth, setInputWidth] = useState<number>(60)
  const [emailWidth, setEmailWidth] = useState<number>(60)
  const [displayMonths, setDisplayMonths] = useState<number>(3)
  const [detail, setDetail] = useState<string>('')
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)

  useEffect(() => {
    // inputTextとtextareaの横幅をレスポンシブ対応。
    const inputRows = width < 700 ? 30 : 60
    setInputWidth(inputRows)
    setEmailWidth(inputRows)
    // デートピッカーの表示月数をレスポンシブ対応。
    const dpMonths = width < 600 ? 1 : width < 900 ? 2 : 3
    setDisplayMonths(dpMonths)
  }, [width])

  useEffect(() => {
    if (!hasValue(states.facilityDetail)) {
      setDetail('')
      setIsShowDetail(false)
      return
    }
    const detail = states.facilityDetail.replaceAll('\n', '<br/>')
    setDetail(detail)
    setIsShowDetail(true)
  }, [states.facilityDetail])

  // ダイアログイベントコールバックハンドラ
  // 確認ダイアログ
  const handleConfirmOk = () => {
    states.closeConfirmDialog()
    states.doCallback()
  }

  const handleConfirmCancel = () => {
    states.closeConfirmDialog()
  }

  // 通知ダイアログ
  const handleNotifyOk = () => {
    states.closeNotifyDialog()
    if (doClose) doClose()
  }

  // エラーダイアログ
  const handleErrorOk = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ConfirmDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showConfirmDialog}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
      />
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={handleErrorOk}
      />
      <NotifyDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showNotifyDialog}
        onOk={handleNotifyOk}
      />
      <section className={fStyles.detailForm}>
        <div className={fStyles.topMargin} />
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="施設名" required />
          </span>
          <span className={fStyles.input}>
            <DropDown
              id="facility"
              size={300}
              list={states.facilities}
              value={states.facilityId.toString()}
              onChange={(event) => states.changeFacilityId(parseInt(event.target.value, 10))}
              errorMsg={states.msg4FacilityId}
              showEmptyOption
            />
          </span>
        </div>
        {isShowDetail && (
          <div className={fStyles.row}>
            <span className={fStyles.label}></span>
            <span className={fStyles.input}>
              <div style={{ marginLeft: '5px', fontSize: '20px'}}>{parse(detail)}</div>
              {/* <TextArea
                id="facilityDetail"
                cols={inputWidth}
                rows={4}
                fontSize={14}
                value={states.facilityDetail}
                disabled
            ></TextArea> */}
            </span>
          </div>
        )}
        {lStates.isAdmin() && (
          <div className={`${fStyles.row} ${fStyles.contractorInfo}`}>
            <span className={fStyles.label}>
              <Label title="予約者" />
            </span>
            <span className={fStyles.outputLayer}>
              {states.isDisplayProxy && (
                <div className={fStyles.innerRow}>
                  <CheckBox
                    id="isProxy"
                    label="契約社の代理登録"
                    checked={states.isProxy}
                    onChange={(event) => states.changeIsProxy(event.target.checked)}
                  />
                  <DropDown
                    id="reserver"
                    size={450}
                    list={states.reserverList}
                    value={states.reserverId.toString()}
                    onChange={(event) => states.changeReserverId(parseInt(event.target.value, 10))}
                    errorMsg={states.msg4ReserverId}
                    showEmptyOption
                    disabled={states.isProxy === false}
                  />
                </div>
              )}
              <div className={fStyles.innerRow}>
                <span className={fStyles.innerLabel}>
                  <Label title="契約社名：" />
                </span>
                <span>{states.reserver.contractorName}</span>
              </div>
              <div className={fStyles.innerRow}>
                <span className={fStyles.innerLabel}>
                  <Label title="担当：" />
                </span>
                <span>{states.reserver.name}</span>
                <span className={fStyles.honorific}>様</span>
              </div>
              {/* <div className={fStyles.innerRow}>
                <span className={fStyles.innerLabel}>
                  <Label title="電話番号：" />
                </span>
                <span>{states.reserver.tel}</span>
              </div> */}
            </span>
          </div>
        )}
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="使用人数" required />
          </span>
          <span className={fStyles.input}>
            <DropDown
              id="facility"
              size={100}
              list={states.userCountList}
              value={states.userCount.toString()}
              onChange={(event) => states.changeUserCount(parseInt(event.target.value, 10))}
              errorMsg={states.msg4UserCount}
              showEmptyOption
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="予約日時" required />
          </span>
          <div className={`${fStyles.input} ${fStyles.multiRows}`}>
            <div className={fStyles.row1}>
              <D1pDatePicker
                id="reservationDatetime"
                showTime
                months={displayMonths}
                clearButton
                notShownPast
                selected={states.reservationDatetime}
                onChange={states.changeReservationDatetime}
                errorMsg={states.msg4ReservationDatetime}
              />
              <span>から開始、</span>
            </div>
            <div className={fStyles.row2}>
              <DropDown
                id="unitTime"
                size={80}
                list={states.timeUnits}
                value={states.timeUnitString}
                onChange={(event) => states.changeTimeUnit(event.target.value)}
                errorMsg={states.msg4TimeUnit}
                showEmptyOption
              />
              <span>時間を予約</span>
            </div>
          </div>
        </div>
        <div className={`${fStyles.row} ${fStyles.inlineBlock}`}>
          <div className={fStyles.label}></div>
          <div className={`${fStyles.input} ${fStyles.multiRows}`}>
            <div className={fStyles.row1}>
              <span className={fStyles.inlineMargin}>予約日時は</span>
              <span className={`${fStyles.inlineMargin} ${fStyles.emphasis}`}>
                {states.reservationDate}
              </span>
              <span className={fStyles.inlineMargin}>の</span>
            </div>
            <div className={fStyles.row2}>
              <span className={`${fStyles.inlineMargin} ${fStyles.emphasis}`}>
                {states.startTime}
              </span>
              <span className={fStyles.inlineMargin} style={{ fontSize: '20px' }}>
                〜
              </span>
              <span className={`${fStyles.inlineMargin} ${fStyles.emphasis}`}>
                {states.endTime}
              </span>
              <span>です。</span>
            </div>
          </div>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="申請者" />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="applicant"
              size={inputWidth}
              maxLength={50}
              value={states.applicant}
              errorMsg={states.msg4Applicant}
              onChange={(event) => states.changeApplicant(event.target.value)}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="メールアドレス" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="replyEmail"
              size={emailWidth}
              maxLength={64}
              value={states.replyEmail}
              errorMsg={states.msg4ReplyEmail}
              placeholder="（確認メール送信先）"
              onChange={(event) => states.changeReplyEmail(event.target.value)}
            />
          </span>
        </div>

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="予約概要" />
          </span>
          <span className={fStyles.input}>
            <TextArea
              id="overview"
              cols={inputWidth}
              rows={4}
              value={states.overview}
              errorMsg={states.msg4Overview}
              onChange={(event) => states.changeOverview(event.target.value)}
            ></TextArea>
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="管理者への連絡事項" />
          </span>
          <span className={fStyles.input}>
            <TextArea
              id="contact"
              cols={inputWidth}
              rows={4}
              value={states.contact}
              errorMsg={states.msg4Contact}
              onChange={(event) => states.changeContact(event.target.value)}
            ></TextArea>
          </span>
        </div>
        <div className={fStyles.footerButtons}>
          {states.isInsert && (
            <button className={fStyles.button} onClick={states.insert}>
              新規登録
            </button>
          )}
          {!states.isInsert && (
            <button className={fStyles.button} onClick={states.update}>
              更新
            </button>
          )}
          <button className={`${fStyles.button} ${fStyles.clear}`} onClick={states.clear}>
            クリア
          </button>
          {!states.isInsert && (
            <button className={`${fStyles.button} ${fStyles.delete}`} onClick={states.remove}>
              予約削除
            </button>
          )}
        </div>
      </section>
    </>
  )
}

export default ReservationForm
