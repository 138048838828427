import { useLocation } from 'react-router-dom'

import InputText from 'components/common/atoms/InputText'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import { useLoginUiState } from 'components/login/LoginUiStateProvider'
import styles from './LoginUiForm.module.scss'
import fStyles from 'styles/utils/form.module.scss'

/**
 *
 */
const LoginUiForm = () => {
  const location = useLocation()
  const isShowExpiredMsg = location.search === '?expired=true'

  const {
    loginId,
    msg4LoginId,
    changeLoginId,
    password,
    msg4Passwd,
    changePassword,
    doLogin,
    clear,
    showErrorDialog,
    dialogTitle,
    dialogContents,
    closeErrorDialog
  } = useLoginUiState()

  return (
    <>
      <ErrorDialog
        title={dialogTitle}
        contents={dialogContents}
        show={showErrorDialog}
        onOk={closeErrorDialog}
      />
      {isShowExpiredMsg && (
        <div className={styles.expiredMessage}>
          一定時間操作がなかったため、自動ログアウトしました。再度ログインして下さい。
        </div>
      )}
      <section className={fStyles.detailForm}>
        <div className={fStyles.row}>
          <span className={fStyles.label}>ログインID</span>
          <span className={fStyles.input}>
            <InputText
              id="loginId"
              size={30}
              value={loginId}
              onChange={(event) => changeLoginId(event.target.value)}
              errorMsg={msg4LoginId}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>パスワード</span>
          <span className={fStyles.input}>
            <InputText
              id="password"
              type="password"
              size={30}
              value={password}
              onChange={(event) => changePassword(event.target.value)}
              errorMsg={msg4Passwd}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>検証用アカウント</span>
          <span className={fStyles.label}>
            <span>管理者：admin / Password01</span><br/>
            <span>契約社：test / Password01</span>
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>(注意)メール送信について</span>
          <span className={fStyles.label}>
            <span>施設を予約した際に送信されるメールは、</span><br/>
            <span>1. 櫻田のOCNのSMTPサーバから送信されます（現状）</span><br/>
            <span>2. 管理者宛のメールは、櫻田の個人メールアドレスに送信されます（インシデント抑止のため）</span><br/>
            <span>3. 契約社宛のメールは、契約社登録情報で指定されたアドレスに送信されます。</span><br/>
          </span>
        </div>
        <div className={fStyles.footerButtons}>
          <button className={fStyles.button} onClick={doLogin}>
            ログイン
          </button>
          <button className={`${fStyles.button} ${fStyles.clear}`} onClick={clear}>
            クリア
          </button>
        </div>
        {/* <div>
          <span>パスワード変更は</span>
          <Link to="/change-password">
            <span
              style={{
                color: 'blue',
                fontWeight: 'bold',
                margin: '0 7px',
                textDecoration: 'underline'
              }}
            >
              こちら
            </span>
          </Link>
          <span>から</span>
        </div> */}
        {/* <div>
          <span>初めての方は</span>
          <Link to="/contractor">
            <span
              style={{
                color: 'blue',
                fontWeight: 'bold',
                margin: '0 7px',
                textDecoration: 'underline'
              }}
            >
              こちら
            </span>
          </Link>
          <span>から契約社情報の登録</span>
        </div> */}
      </section>
    </>
  )
}

export default LoginUiForm
