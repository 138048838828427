import InputFile from 'components/common/atoms/InputFile'
import { DownloadFileInfo } from 'types/infos/DownloadFileInfo'
import { useUploadFileState } from './UploadFileStateProvider'
import InputText from 'components/common/atoms/InputText'
import ConfirmDialog from 'components/common/dialogs/messages/ConfirmDialog'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import NotifyDialog from 'components/common/dialogs/messages/NotifyDialog'
import Label from 'components/common/atoms/Label'
import CheckBox from 'components/common/atoms/CheckBox'
import fStyles from 'styles/utils/form.module.scss'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { useEffect } from 'react'

/**
 *
 */
interface UploadFileFormProps {
  execType: EXEC_TYPE
  uploadFileInfo: DownloadFileInfo
  doClose?: () => void
}

/**
 *
 * @param props
 * @returns
 */
const UploadFileForm = (props: UploadFileFormProps) => {
  //
  const { execType, uploadFileInfo, doClose } = props
  //
  const states = useUploadFileState()

  // 初期値
  const isInsert = execType === EXEC_INSERT
  const isUpdate = execType === EXEC_UPDATE

  // 画面初期化
  useEffect(() => {
    // 新規登録時
    if (execType === EXEC_INSERT) {
      states.refresh(execType)
      return
    }

    // 更新時
    if (execType === EXEC_UPDATE) {
      states.refresh(execType, uploadFileInfo!)
    }
  }, [])

  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  const doOnConfirmOk = async () => {
    states.closeConfirmDialog()
    await states.doCallback()
  }

  const doOnConfirmCancel = () => {
    states.closeConfirmDialog()
  }
  const closeNotifyDialog = () => {
    states.closeNotifyDialog()
    doClose!()
  }

  return (
    <>
      <ConfirmDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showConfirmDialog}
        onOk={doOnConfirmOk}
        onCancel={doOnConfirmCancel}
      />
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <NotifyDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showNotifyDialog}
        onOk={closeNotifyDialog}
      />

      <section className={fStyles.detailForm}>
        <div className={fStyles.topMargin} />
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="公開ファイル名" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="publicName"
              size={50}
              maxLength={50}
              value={states.publicName}
              onChange={(event) => states.changePublicName(event.target.value)}
              errorMsg={states.msg4PublicName}
            />
          </span>
        </div>

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="ファイル" required />
          </span>
          <span className={fStyles.input}>
            <InputFile
              id={1}
              value={states.file}
              fileName={states.originalName}
              onChange={states.changeFile}
              errorMsg={states.msg4File}
            />
          </span>
        </div>

        {/* <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="使用開始日" required />
          </span>
          <span className={fStyles.input}>
            <D1pDatePicker
              id="usageStartDate"
              width={150}
              clearButton
              selected={states.usageStartDate}
              onChange={(event) => states.changeUsageStartDate(event)}
              errorMsg={states.msg4UsageStartDate}
            />
          </span>
        </div> */}

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="使用停止" />
          </span>
          <span className={fStyles.input}>
            <CheckBox
              id="isSuspended"
              label="ダウンロード資料を残したまま、一時的に契約社に非公開とする場合にチェック"
              checked={states.isSuspended}
              onChange={(event) => states.changeIsSuspended(event.target.checked)}
            />
          </span>
        </div>
        <div className={fStyles.footerButtons}>
          {isInsert && (
            <>
              <button className={fStyles.button} onClick={states.insert}>
                新規登録
              </button>
              <button className={`${fStyles.button} ${fStyles.clear}`} onClick={states.clear}>
                クリア
              </button>
            </>
          )}
          {isUpdate && (
            <>
              <button className={fStyles.button} onClick={states.update}>
                更新
              </button>
              <button className={`${fStyles.button} ${fStyles.delete}`} onClick={states.remove}>
                ファイル削除
              </button>
            </>
          )}
        </div>
        {isUpdate && (
          <div className={fStyles.row}>
            <span className={fStyles.label}></span>
            <span className={fStyles.input}>
              更新時はファイルのクリアができません。
              <br />
              入力をやり直す場合、画面を一旦閉じて再度表示して下さい。
            </span>
          </div>
        )}
      </section>
    </>
  )
}

export default UploadFileForm
