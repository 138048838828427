/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactNode, useEffect, useRef } from 'react'

import styles from 'components/reservations/reservations/ReservationsForm.module.scss'
import ReservationCell from './ReservationCell'
import { ReservationInsertInfo } from 'types/ui/ReservedInfo'
import { FacilityListElement } from 'types/rows/FacilityListElement'
import { calcSelectDatetime } from 'utils/dateUtil'

interface ReservationFacilitiesTableProps {
  children?: ReactNode
  targetDate: Date
  items: FacilityListElement[]
  disabledIndex: number
  isReadOnly: boolean
  selectFacility: (id: number) => void
  onClick?: (params: ReservationInsertInfo) => void
}

/**
 *
 * @param param0
 * @returns
 */
const ReservationFacilitiesTable = ({
  children,
  targetDate,
  items,
  disabledIndex,
  isReadOnly,
  selectFacility,
  onClick
}: ReservationFacilitiesTableProps) => {
  //
  const ref1 = useRef<HTMLDivElement | null>(null)
  //
  const rowCount = items.length
  const colCount = 24 * (60 / 30)

  const clickHandler = (rowIndex: number, colIndex: number) => {
    // デフォルトの予約時間数は１時間
    const timeUnit = 2

    // パラメータを生成
    const params: ReservationInsertInfo = {
      // rowIndexからfacilityIdを解決
      facilityId: getFacilityId(rowIndex),
      // colIndexと対象年月日から予約日時を解決
      reservationDatetime: calcSelectDatetime(targetDate, colIndex),
      //
      timeUnit
    }

    onClick!(params)
  }

  /**
   *
   * @param rowIndex
   * @returns
   */
  const getFacilityId = (rowIndex: number) => {
    return items[rowIndex].facilityId
  }

  useEffect(() => {
    // const child1 = ref1.current!.children
    // const grandChild4 = child1[0].children
    // console.log('CHILD1 ? ', grandChild4)
  }, [])

  return (
    <div className={styles.bodyRows} ref={ref1}>
      {[...Array(rowCount)].map((_, rIdx) => (
        <div className={styles.bodyRow} key={`reservation-table-row-${rIdx}`}>
          {/* index列 */}
          {rIdx === 0 && (
            <div ref={ref1} className={`${styles.body} ${styles.colFix1} ${styles.index}`}>
              {rIdx + 1}
            </div>
          )}
          {rIdx !== 0 && (
            <div className={`${styles.body} ${styles.colFix1} ${styles.index}`}>{rIdx + 1}</div>
          )}
          {/* 施設名列 */}
          <div className={`${styles.body} ${styles.colFix2} ${styles.titleColumn}`}>
            {isReadOnly && (
              <span>
                {items[rIdx].facilityName}
              </span>
            )}
            {!isReadOnly && (
              <a href="#" onClick={() => selectFacility(items[rIdx].facilityId)}>
                {items[rIdx].facilityName}
              </a>
            )}
          </div>
          {/* 時刻セル */}
          <div className={styles.timeCellArea}>
            {[...Array(colCount)].map((_, cIdx) => (
              <ReservationCell
                rowIndex={rIdx}
                colIndex={cIdx}
                facilityInfo={items[rIdx]}
                onClick={clickHandler}
                disabledIndex={disabledIndex}
                key={`reservation-table-cell-${rIdx}-${cIdx}`}
              />
            ))}
          </div>
        </div>
      ))}
      {children}
    </div>
  )
}

export default ReservationFacilitiesTable
