import styles from 'components/common/PageTitle.module.scss'

const PageTitle = (props: any) => {
  const title = props.title
  const existDescription = props.existDescription
  const descStyle = existDescription ? styles.descStyle : ''

  return <div className={`${styles.pageTitle} ${descStyle}`}>&nbsp;{title}&nbsp;</div>
}

export default PageTitle
